.height-half-screen {
  height: 50vh;
}  

.width-225px {
  width: 225px;
}
 
.hight-225px {
 height: 100px;
}
 
.width-90-percent{
  width: 90%;
}